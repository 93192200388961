.sessionLoading {
  position: absolute;
  top: 300px;
  left: 640px;
}

.tableRowTitles,
.tableRowValues {
  display: inline-block;
  width: 170px;
  height: 97px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #0c3957;
}

.linkLabels {
  position: absolute;
  top: 199px;
  left: 30px;
}

p {
  margin: 0px;
}

.linkChips {
  position: relative;
  top: 255px;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin-left: 33px;
}

.linkChip {
  margin-bottom: 19px;
}

.additionalInformationLinks {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.sidePanelCheckboxes {
  position: absolute;
  left: 880px;
  top: 32px;
  width: 374px;
  height: 654px;
  background-color: #ffffff;
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  /* padding-bottom: 10px; */
}

.checklistTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 46px;
  margin-top: 2px;
  padding-left: 37px;
  padding-bottom: 0px;
}

.checklistItem {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 374px;
  height: 35px;
  margin: 0px 0px 10px 0px !important;
  padding-left: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #0c3957;
  user-select: none;
}

.clientCheckboxText {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 22px;
}
.home {
  margin-right: 765px;
}
