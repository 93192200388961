@keyframes toastLoadingAnimation {
  0% {
    height: 100;
    opacity: 1;
    border-radius: 10px 0px 0px 10px;
  }
  20% {
    border-radius: 10px 0px 0px 0px;
  }
  40% {
    opacity: 1;
  }
  100% {
    height: 0;
    opacity: 0;
    border-radius: 10px 0px 0px 0px;
  }
}

.successLoadingBarInFront {
  width: 40px;
  height: 100px;
  background-color: rgb(59, 185, 196);
  animation-name: toastLoadingAnimation;
  animation-duration: 5s;
  animation-fill-mode: forwards;
  float: left;
  border-radius: 10px 0px 0px 10px;
  z-index: 2;
  position: relative;
}

.successLoadingBar {
  width: 40px;
  height: 100px;
  background-color: rgb(43, 155, 165);
  position: relative;
  float: left;
  border-radius: 10px 0px 0px 10px;
  z-index: 1;
}

.errorBar {
  width: 40px;
  height: 100px;
  background-color: rgb(140, 1, 37);
  float: left;
  border-radius: 10px 0px 0px 10px;
}

.successToastText {
  color: white;
  align-items: center;
  padding-top: 40px;
  padding-right: 115px;
  font-family: Brother 1816;
  font-size: 16px;
  float: right;
  font-weight: 500px;
}

.success2ToastText {
  color: white;
  align-items: center;
  display: flex;
  height: 100px;
  padding-left: 5px;
  font-family: Brother 1816;
  font-size: 16px;
  font-weight: 500px;
}

.errorToastText {
  color: white;
  align-items: center;
  padding-top: 40px;
  padding-right: 60px;
  font-family: Brother 1816;
  font-size: 16px;
  float: right;
  font-weight: 500px;
}

.error2ToastText {
  color: white;
  align-items: center;
  padding-top: 40px;
  padding-right: 95px;
  font-family: Brother 1816;
  font-size: 16px;
  float: right;
  font-weight: 500px;
}

.close {
  position: relative;
  left: 260px;
  top: 10px;
  opacity: 0.3;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 20px;
  width: 2px;
  background-color: #ffffff;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

.errorIconPosition {
  margin-left: 7px;
  margin-top: 37px;
}

.successIconPosition {
  margin-left: 12px;
  margin-top: 41px;
  position: absolute;
  z-index: 3;
}
