.warningContainer {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-content: center;
  justify-content: center;
  height: 800px;
  width: 100%;
  z-index: 3;
}

.message {
  align-self: center;
  background: #ffffff;
  border: 4px solid #b40125;
  display: flex;
  align-content: center;
  justify-content: center;
  border-radius: 8px;
  height: 200px;
}

.column {
  flex-direction: column;
}

.warningButtonContainer {
  display: flex;
  align-content: center;
  justify-content: center;
}

.warningText {
  align-self: top;
  padding: 30px !important;
}
