.goalAllocateBarContainer {
    display: flex;
    align-content: center;
    height: 40px;
    margin: 5px;
}

.newPensionGoalAllocationButtons {
    display: flex;
    flex-direction: column;
    height: 400px;
}

.newPensionGoalAllocation {
    display: flex;
    align-content: center;
}

.goalAllocationButton {
    display: flex;
    align-content: center;
    height: 34px;
    margin-right: 15px;
}

.goalAllocationFieldInput, .goalAllocationFieldInput:focus {
    width: 72px;
    height: 30px;
    padding: 2px;
    margin: 0px;
    margin-right: 10px;
}

.percentSign {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #0C3957;
    user-select: none;
}