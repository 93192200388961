.button {
	margin: 0px;
	margin-top: 5.5px;
}

.incrementorContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 0px;
	width: 270px;
}

.incrementorValue {
	margin: 7.5px;
	box-sizing: border-box;
	background-color: #F5F8FA;;
	width: 150px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.text {
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	width: 150px;
	height: 40px;
	box-sizing: border-box;
	user-select: none;
}
