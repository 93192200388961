.cardContainer {
  padding-top: 50px;
  height: 633px;
  width: 691px;
}

.aboutCardHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 43px !important;
}

.aboutCardText {
  padding-top: 10px;
  height: 90px;
  font-weight: normal !important;
  text-align: center;
}

.imageContainer {
  padding-top: 40px;
  padding-bottom: 50px;
}
