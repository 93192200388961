.graphInfo {
  position: absolute;
  top: 160px;
  left: 505px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: Brother 1816;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 30px;
  color: #0c3957;
}

.retirementGoalGraphHeader {
  position: absolute;
  top: 30px;
  left: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 600px;
  font-family: Brother 1816;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  /* identical to box height, or 186% */

  display: flex;
  align-items: center;

  /* Text/Copy/Primary */

  color: #0c3957;
}

.retirementGraphHeader {
  width: 105px;
}

.graphHeaderItem {
  padding-left: 20px;
  font-weight: normal !important;
}

.pensionsGraphAmount {
  font-family: Brother 1816;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 30px;
  color: #3f762c;
}

.investmentGraphAmount {
  font-family: Brother 1816;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 30px;
  color: #247ba0;
}

.cashGraphAmount {
  font-family: Brother 1816;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 30px;
  color: #69306d;
}
