.riskLevelGoal {
  display: flex;
  align-items: center;
}

.riskLevelGoalTab {
  margin-right: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.goalInvestment {
  position: absolute;
  top: 3px;
  left: 24px;
}

.investmentTitleRisk {
  margin-right: 100px;
}

.riskInvestment {
  position: absolute;
  top: 3px;
  left: 760px;
}
