.niNumbers {
    position: relative;
    top: 250px;
    left: 28px;
    display: flex;
}

.niNumberField, .niNumberField:focus {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    width: 470px;
    height: 50px;
}

.niNumberLabel {
    width: 150px;
    height: 28px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    padding: 10px;


    /* Text/Copy/Primary */

    color: #0C3957;
}

.niNumber, .niNumberInput, .niNumberInput:focus {
    width: 240px !important;
    margin: 0px;
    margin-left: 20px;
}

.address {
    position: absolute;
    top: 300px;
    left: 40px;
}

.addressInput, .addressInput:focus {
    width: 710px !important;
    margin-left: 42px;
}