.goalInformation {
	display: flex;
	flex-direction: row;
	align-items: start;
	justify-content: start;
	position: absolute;
	height: 474px;
	left: 312px;
	right: 278px;
	top: 134px;
	background: #ffffff;
	box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
}

.aboutYourGoals {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-left: 45px;
	height: 566px;
	width: 1235px;
}

.leftPanel {
	display: flex;
	flex-direction: row;
	align-items: start;
	justify-content: start;
	width: 148px;
	height: 566px;
}

.leftArrow {
	position: relative;
	top: 177px;
	transform: scaleX(-1);
}

.rightArrow {
	position: relative;
	top: 177px;
	right: 45px;
}

.centrePanel {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 982px;
	height: 566px;
}

.centreShaded {
	position: relative;
	height: 474px;
	width: 187px;
	left: 0px;
	top: 0px;
	background: #BF802F;
	opacity: 0.1;
	border-radius: 8px 0px 0px 8px;
}

.centreShaded.Education {
	background: #69306d;
}

.centreShaded.Property {
	background: #247BA0;
}

.centreShaded.Family {
	background: #3F762C;
}

.centreShaded.LongTermInvestment {
	background: #373986;
}

.centreShaded.CareFees {
	background: #0C3957;
}

.centreShaded.Other {
	background: #F25F5C;
}

.rightPanel {
	display: flex;
	flex-direction: row;
	align-items: start;
	justify-content: flex-end;
	width: 148px;
	height: 566px;
}
