.investmentSummaryContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 42px;
  width: 1150px;
  background: #f5f8fa;
  border-radius: 4px;
  margin-bottom: 10px;
}

.investmentField {
  display: flex;
  align-items: center;
}

.investmentFieldPensionRecommendation {
  position: absolute;
  top: 0px;
  left: 300px;
}

.whoInvestment {
  position: absolute;
  top: 5px;
  left: 8px;
}

.wrapperInvestment {
  position: absolute;
  top: 5px;
  left: 305px;
}

.contributionInvestment {
  position: relative;
  top: 0px;
  left: 295px;
}

.recommendationInvestment {
  position: absolute;
  top: 3px;
  left: 822px;
}

.pensionInvestment {
  position: absolute;
  top: 3px;
  left: 550px;
}

.investmentTitle {
  font-family: Brother 1816;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 32px;
  color: #000000;
}

.investmentItem {
  font-family: Brother 1816;
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 32px;
  color: #000000;
  padding-left: 10px;
  margin-right: 10px;
}

.investmentFund {
  margin-left: 370px;
}

.investmentItemLong {
  font-size: 17px !important;
}

.bigmargin {
  margin-right: 100px;
}
