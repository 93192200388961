.barContainer {
  display: flex;
  align-items: flex-start;
  justify-content: start;
  margin-bottom: 20px;
  width: 1200px;
  touch-action: none;
}

.barName {
  width: 156px;
  height: 40px !important;
  margin-left: 20px !important;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.barAmountUnit {
  width: 10px;
  height: 40px !important;
  margin-left: 60px !important;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.barAmount,
.barAmount:focus {
  width: 60px;
  height: 40px;
  margin-left: 0px !important;
  font-family: Brother 1816;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: left;
  color: #0c3957;
  user-select: none;
  border: none;
  outline: none;
  background-color: #f4f4f4;
  user-select: none;
}

.bar {
  position: relative;
  left: 30px;
  border-radius: 0px 4px 4px 0px;
  overflow-x: hidden !important;
  touch-action: none;
}

.isFeint {
  opacity: 0.3;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -webkit-user-select: none;
  user-select: none;
}

input::selection {
}
