.optionsOnRetirement {
    margin-top: 20px;
    margin-bottom: 8px;
    margin-left: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #0C3957;
}

.ownerPensionButtonRightCol {
    margin-left: 70px;
}