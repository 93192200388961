.nextButton {
    display: flex;
    align-items: center;
}

.backPara {
    margin-left: 10px;
}

.nextPara {
    margin-right: 10px;
}