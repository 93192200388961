.sortOutContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.sortOutText {
  margin-left: 10px !important;
  line-height: 25px !important;
}
