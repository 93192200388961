.topBarContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 1200px;
  height: 80px;
  margin-top: 23px;
  margin-bottom: 21px;
  padding-left: 18px;
  background: #ffffff;
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.comforts {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -7px;
  width: 131px;
}

.topBarDivider {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px !important;
}

.retirementAge {
  width: 280px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: flex-end;
  font-size: 18px;
  line-height: 26px;
  color: #0c3957;
  margin-left: 100px;
}

.retirementAgeLabel {
  line-height: 26px !important;
  display: flex;
  align-items: right;
}

.alignRight {
  display: flex;
  flex-direction: row;
  text-align: right;
}

.boldMargin {
  font-weight: bold !important;
  margin-left: 20px !important;
}

.topBarSecondDivider {
  padding: 0.5px;
}

.topBarIsBold {
  font-weight: bold !important;
  line-height: 26px !important;
}
