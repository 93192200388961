.existingPensionContainer {
  position: relative;
}

.existingPensionTable {
  background: #f5f8fa;
  border-radius: 4px;
}

.existingPensionTableDimensions {
  height: 435px;
  width: 1143px;
}
.existingInvestmentTableDimensions {
  height: 260px;
  width: 1143px;
}

.existingPensionField {
  position: relative;
  height: 36px;
}

.existingPensionSummaryItem {
  position: absolute;
  top: 3px;
  left: 272px;
}

.existingPensionRecommendation {
  margin-top: 10px;
  width: 544px;
}

.recommendationTable {
  margin-top: 41px !important;
  margin-bottom: 21px !important;
}

.recommendationSubHeading {
  margin-bottom: 20px;
}

.recommendationHeaderText {
  font-family: Brother 1816;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 32px;

  color: #000000;
}

.recommendationText {
  font-family: Brother 1816;
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 32px;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 20px;
}
