.unauthorisedContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 0;
  background-color: #B1DECA;
}

.whiteContainer {
  width: 600px;
  height: 600px;
  background: #ffffff;
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-bottom: 13vh;
  }

.unauthorisedReturn {
  cursor: pointer;
  margin-top: 45px;
  margin-bottom: 3vh;
  display: flex;
  align-items: center;
  justify-content: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  justify-content: center;
}

hr {
  width: 100%;
  margin-bottom: 20px;
  background-color: #ffffff;
}

.ammoniteLogoContainer {
  position: realtive;
  margin-bottom: 3vh;
}


  h3 {
    text-align: center;
    padding-top: 30px;
    padding-bottom:30px;
    background-color: #ffffff;
  }

  .textBody2 {
    text-align: center;
    background-color: #ffffff;
    padding:35px;
  }

  .Lock {
  display: flex;
  align-items: center;
  justify-content: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  justify-content: center;
   margin-top: 3vh;
  }