.cookieBannerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  min-width: 200px;
  background-color: #ffffff;
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.95);
  border-radius: 8px;
  margin: 15px;
}

.cookieBannerBlocker {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  overflow: hidden;
}

.cookieLogo {
  transform: scale(0.2);
  height: 35px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.cookieButtons {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.cookieText {
  font-family: "Roboto";
  font-size: 18px;
  color: #232323;
  line-height: 30px;
  margin: 50px 50px 20px 50px;
}

.cookieCheckbox {
  font-family: "Roboto";
  font-size: 19px;
  font-weight: bold;
  color: #232323;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.cookieCheckboxLabel {
  height: 25px;
}

.cookieAboutText {
  font-family: "Roboto";
  font-size: 18px;
  color: #232323;
  line-height: 30px;
  margin-bottom: 20px;
}

.cookieText > a {
  font-size: 18px;
}

.cookieType {
  margin: 30px 50px 0px 50px;
}
