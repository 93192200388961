.clientAttitudeToRiskTitle {
    margin-top: 11.5px;
    margin-left: 25px;
}

.clientAttitudeToRiskButtons {
    height: 56px;
}

.riskContainer {
    width: 180px;
}

.riskInput, .riskInput:focus {
    height: 38px;
}

textarea {
    font-family: 'Roboto', sans-serif;
}