.stepProgressContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 46px;
  width: 1280px;
}

.stepButton {
  height: 40px;
  width: 40px;
}
