.transferISA {
    display: flex;
    position: absolute;
    top: 444px;
    right: 0px;
}

.sell {
    display: flex;
    position: absolute;
    top: 500px;
    right: 0px;
}

.transferISAtext {
    margin-top: 34px;
}
