@font-face {
  font-family: "Brother";
  src: local("Brother"),
    url(./Fonts/TipoType - Brother-1816-Light-Italic.otf) format("otf");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 600px) {
  .html .body #root {
    display: inline-block;
    position: relative;
    width: auto;
    height: 500px;
    overflow: hidden;
    margin-top: 200px;
  }
  /* For mobile phones: */
  [class*="body"] {
    display: inline-block;
    width: auto;
    height: 500px;
    overflow: hidden;
  }
}
