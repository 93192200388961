.menuContainer {
  height: 800px;
  z-index: 3;
}

.menuHeaderContainer {
  margin-top: 30px;
  margin-left: 30px;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-left: 30px;
}
