.sessionCardText {
  font-family: Brother 1816;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #0c3957;
}

.sessionCardMultipleChoice {
  position: relative;
  display: flex;
  top: 12px;
}

.sessionCardMultipleChoiceText {
  position: relative;
  left: 10px;
  top: -10px;
}

.sessionCardInput,
.sessionCardInput:focus {
  background: #f5f8fa;
  border: none;
  border-radius: 4px;
  padding: 10px 23px;
  font-family: Brother 1816;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #0c3957;
  width: 75vw;
  max-width: 850px;
  outline: none;
}

.sessionCardBiggerInput,
.sessionCardBiggerInput:focus {
  height: 210px;
  outline: none;
}

.sessionCardSmallInput,
.sessionCardSmallInput:focus {
  width: 300px;
}

.sessionCardBetweenComponents {
  margin-top: -5px !important;
}

.sessionCardBetweenComponents2 {
  margin-bottom: 10px !important;
}

.sessionCardAreaOfInterest,
.sessionCardAreaOfInterest:focus {
  height: 208px;
  outline: none;
}

.sessionCardGoalsContainer {
  max-height: 505px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-left: -25px;
  /* margin-left: -20px; */
}

.sessionCardGoalsFirstRow {
  margin-top: 10px !important;
}

.sessionCardFHCTitle {
  color: #bf802f;
  font-family: Brother 1816;
  font-weight: 500;
  font-size: 25px;
  margin-top: 20px;
  margin-bottom: 25px;
}

.sessionCardDropdown div {
  width: 390px;
  margin-top: -4px;
  margin-left: -5px;
}

.sessionCardGoalsPane {
  max-height: 500px;
  overflow-y: scroll;
}