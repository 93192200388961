.childCardContainer {
	display: flex;
	align-items: flex-start;
	flex-direction: row;
	padding: 0px;
	margin-right: 20px;
	margin-bottom: 20px;
	height: 152px;
    width: 488px;
	background: #ffffff;
	box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	overflow: hidden;
}

.childrenContainer {
    display: flex;
    flex-direction: column;
	margin: 0px;
	margin-left: -20px;
}

.childFields {
    display: flex;
    margin-top: 10px;
}

.childLabel {
    margin-left: 27px;
    font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 26px;
	color: #0C3957;
}

.nameInput, .nameInput:focus {
	background: #f5f8fa;
	border: none;
	border-radius: 4px;
	height: 19px;
	width: 182px;
    margin: 10px 21px;
	padding: 10px 10px;
	font-family: Brother 1816;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 26px;
	color: #0c3957;
}

.childTrash {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	width: 40px;
	top: 0px;
	left: 440px;
}
