/* ----------------PAGES---------------- */

/* ------------Log In Page------------ */

@media screen and (max-height: 823px) {
  .loginCard {
    margin-bottom: 2vh !important;
  }
}

@media screen and (max-height: 733px) {
  .loginCard {
    margin-bottom: 11vh !important;
  }
  .ammoniteLogoContainer {
    transform: scale(0.6) !important;
    margin-bottom: -3vh !important;
  }
  .loginInfoText {
    bottom: 8vh !important;
  }
}

@media screen and (max-height: 690px) {
  .loginCard {
    height: 360px !important;
    margin-bottom: 11vh !important;
  }
  .loginButtonContainer {
    margin-top: 35px !important;
  }
  .loginHeader {
    margin-top: 20px !important;
  }
}

@media screen and (max-width: 500px) {
  .loginCard {
    margin-bottom: 10vh !important;
  }
  .loginInfoText {
    bottom: 8vh !important;
  }
}
