.toastContainer {
    height: 89px;
    width: 525px;
    background: #FFFFFF;
    box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
}

.toastIllustration {
    height: 48.99px;
    padding-top: 24.8px;
    padding-left: 15px;
}

.toastTextContainer {
    position: absolute;
    top: 21px;
    left: 99px;
}

.house {
    padding-left: 20px;
}