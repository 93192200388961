.goalCardMainContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  padding: 0px;
  margin-right: 20px;
  width: 387px;
  height: 151px;
  background: #ffffff;
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  overflow: hidden;
}

.cardBody {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 372px;
  height: 151px;
}

.cardHeader {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  margin-top: 18px;
  margin-left: 15px;
}

.goalTypeContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 243px;
  height: 30px;
}

.deleteContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  margin-top: 2px;
  margin-left: 14px;
  height: 30px;
}

.deleteIcon {
  margin-right: 10px;
}

.deleteText {
  font-family: Brother 1816;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #7a7a7a;
  flex: none;
  order: 1;
  align-self: flex-end;
  margin: 5px 0px;
}

.goalText,
.goalText:focus {
  margin-left: 15px;
  margin-right: 15px;
  background: #f5f8fa;
  border: none;
  border-radius: 4px;
  height: 46px;
  width: 296px;
  padding: 10px 23px;
  font-family: Brother 1816;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
}
