.reportGoalCardContainer {
	height: 311px;
	display: flex;
	align-items: flex-start;
	padding: 0px;
	margin-left: 20px;
	margin-right: 5px;
	width: 263px;
	background: #ffffff;
	box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	overflow: hidden;
}

.reportGoalCardBody {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	width: 263px;
	height: 311px;
}

.reportGoalCardHeader {
	width: 263px;
	margin-left: 10px;
	padding-top: 20px;
}

.reportGoalDivider {
	margin-left: 0px;
    height: 25px;
    width: 218px;
    display: flex;
    align-items: center;
}

.reportGoalNameContainer {
	font-family: Brother 1816;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 30px;
	color: #0c3957;
}

.reportGoalTypeContainer {
	font-family: Brother 1816;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 26px;
	color: #0c3957;
}

.reportGoalCardMiddle {
	width: 263px;
	margin-left: 10px;
	/* padding-bottom: 5px; */
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.reportGoalMiddleContainer {
	padding-right: 15px;
}

.reportGoalRequiredTodayLabel {
	font-family: Brother 1816;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 26px;
    color: #0c3957;
    padding-top: 5px;
}

.reportGoalRequiredTodayContainer {
	font-family: Brother 1816;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 26px;
	display: flex;
	align-items: center;
	color: #0c3957;
}

.reportGoalCardEnd {
    display: flex;
	align-items: flex-start;
	width: 263px;
	margin-left: 10px;
}


