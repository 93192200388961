.checkboxContainer {
  display: flex;
  align-items: center;
  margin-top: 25px;
  height: 44px;
}

.checkboxUnchecked {
  min-width: 36px;
  max-width: 36px;
  height: 36px;
  /* border: 4px solid #3bb9c4; */
  box-sizing: border-box;
  border-radius: 8px;
  background: #ffffff;
}

.checkboxChecked {
  position: relative;
  min-width: 36px;
  max-width: 36px;
  height: 36px;
  border: none;
}

.checkboxText {
  margin: 0px 15px !important;
  font-weight: 400;
}

.isBold {
  font-weight: 800 !important;
}

.isInline {
  margin-top: 27px !important;
}

.halfSize {
  transform: scale(0.5);
  margin-top: 36px;
}
