/* NEW PENSION ADVICE PAGE */

.investmentStrategyFieldLabel {
  margin-right: 35px;
}

.newPensionsInvestmentStrategy {
  position: absolute;
  top: 472px;
  left: 10px;
}

.includeAnnualAllowanceDetailsCheckboxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.includeAllowanceDetailsLabel {
  margin-top: 25px;
  margin-left: -15px;
}

.newPensionInvestmentStrategyButtonsContainer {
  margin-left: 200px;
}

.newPensionInvestmentButtonWidth {
  width: 150px;
}

.newPensionInvestmentStrategyFieldLabel {
  position: absolute;
  margin-top: 16px;
  margin-left: 8px;
}

/* NEW CASH ADVICE PAGE */

.allocationOfGoalsNewCash {
  margin-top: 60px;
}

.allocationText {
  margin-left: 70px;
  margin-bottom: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  font-family: Brother 1816;
  user-select: none;
}
