.goalRecommendationContainer {
    display: flex;
    width: 1148.03px;
    background: #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.goalRecommendationsPanel {
    position: absolute;
    display: flex;
    flex-direction: column;
}

.colourBar {
    width: 66px;
}

.goalRecommendationsCentreLine {
    position: absolute;
    top: 65px;
    left: 125px;
}

.goalRecommendationsTop {
    position: absolute;
    top: 0px;
    left: 75px;
    display: flex;
    width: 1015px;
    margin-left: 50px;
}

.goalRecommendationsTopRow {
    width: 1015px;
    height: 80px;
}

.goalRecommendationsBottom {
    position: absolute;
    top: 75px;
    left: 75px;
    display: flex;
    width: 1015px;
    margin-left: 50px;
}



.goalRecommendationsTopRowTitle {
    margin-top: 22px;
    font-family: Brother 1816;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #0C3957;
}

.goalRecommendationsTopRowTitleLong {
    width: 245px !important;
}

.goalRecommendationsTopRowValue {
    font-family: Brother 1816;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #0C3957;
}

.investmentSolution {
    font-size: 12px;
}

.platform {
 width: 200px;
}