.mainRetirementContainer {
  margin-left: 40px;
}

.whiteScreen {
  position: absolute;
  top: 0;
  left: 0;
  height: 140px;
  width: 1280px;
  background-color: #f4f4f4;
  z-index: 2;
}

.retirementTopBar {
  position: absolute;
  top: 147px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 1200px;
  height: 80px;
  background: #ffffff;
  transition-delay: 0.05s;
  transition-duration: 0.05s;
  transition-timing-function: ease-in-out;
  /* box/standard */

  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  z-index: 2;
  user-select: none;
}

.topBarToTop {
  top: 30px;
}

.retirementGraphToComforts {
  top: 200px;
}

.retirementGraph {
  position: absolute;
  top: 136px;
  padding-top: 120px;
  height: 455px;
  width: 1200px;
  overflow-x: hidden;
  overflow-y: auto;
}

.essentials {
  display: flex;
  flex-direction: column;
  margin-left: 0px !important;
  padding-left: 0px;
  user-select: none;
}

.essentialsHeader {
  height: 40px !important;
  width: 1180px;
  border-bottom: 1px solid #bdbdbd;
  margin: 0px 20px 20px 20px !important;
  display: flex;
  align-items: flex-start;
}

.retirementGraph::-webkit-scrollbar {
  display: none;
}
