.childPanel {
  position: relative;
  top: 119px;
  left: 40px;
  width: 488px;
  height: 152px;
  margin-right: 100px;
}

.childDetails {
  position: relative;
}

.addChild {
  position: absolute;
  top: 630px;
  left: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addChildLabel {
  margin-left: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 32px;
  color: #0c3957;
}

.childPanelCols {
  display: flex;
  overflow-x: scroll;
  height: 700px;
  scroll-behavior: auto;
}
