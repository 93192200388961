.roundSwitchContainer {
  height: 30px;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 7px;
  margin-right: 10px;
}

.roundSwitch {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  width: 36px;
}

.switchText {
  line-height: 26px !important;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  padding: 0px;
}

.isBold {
  font-weight: bold;
}
