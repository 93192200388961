.user {
  position: absolute;
  /* display: flex;
    justify-content: center;
    align-items: center; */
  top: 35px;
  left: 71px;
}

.userName {
  margin-left: 50px;
}

.userUid {
  font-weight: 100;
  font-size: 12px;
  line-height: 12px;
}

.userRoleDropdown {
  position: absolute;
  top: 25px;
  left: 1040px;
  display: flex;
}

.editIconContainer {
  display: flex;
  margin-top: 2.5px;
  align-items: flex-end;
  justify-content: center;
  height: 40px;
  width: 40px;
}
