.buttonGroup {
	display: flex;
	flex-direction: column;
	height: 84px;
	margin-top: -18px;
}

.buttonHandlerContainer {
	margin-left: 4px;
	margin-bottom: -2px;
	height: 36px;
}

.buttonLabelGroup {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.buttonLabel {
	margin-left: 20px;
}

.circleSVG {
	position: relative;
	top: -18px;
	left: 0px;
}

.tickSVG {
	position: relative;
	top: 15px;
	left: 9px;
	z-index: 2;
}

.lineSVG {
	padding: 0px;
}
