.coveredContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
}

.coveredTick {
  height: 30px;
}

.coveredText {
  width: 247px;
}
