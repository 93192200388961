.routeButtonsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 0px;
}

.routeButtons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 70%;
  height: 200px;
  margin: 0%;
  padding: 0%;
  border-radius: 8px;
  padding-top: 40px;
}

.chooseRouteButtonContainer {
  padding-left: 40px;
  padding-right: 40px;
}

.routeLogo {
  transform: scale(1.1);
}

.routeLoginContainer {
  height: 100vh;
  width: 100vw;
}
