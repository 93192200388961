.riskTableContainer {
    height: 87px;
    width: 1067px;
    background: #F5F8FA;
    border-radius: 4px;
}

.riskTableTopRow {
    position: relative;
    display: flex;
    top: 5px;
}

.riskTableFirstHeading {
    position: absolute;
    left: 265px;
}

.riskTableSecondHeading {
    position: absolute;
    display: flex;
    left: 440px;
}

.riskTableThirdHeading {
    position: absolute;
    display: flex;
    left: 660px;
}

.riskTableFourthHeading {
    position: absolute;
    display: flex;
    left: 880px;
}

.riskTableItem {
    font-family: Brother 1816;
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    line-height: 32px;
    color: #000000;
    padding-left: 12px;
    padding-top: 3px;
}

