.validationWarningContainer {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-content: center;
  justify-content: center;
  height: 800px;
  width: 100%;
  z-index: 3;
}

.validationMessage {
  align-self: center;
  background: #ffffff;
  border: 4px solid #b40125;
  display: block;
  align-content: center;
  justify-content: center;
  border-radius: 8px;
  height: 200px;
  width: auto;
}

.warningButtonContainer {
  display: flex;
  align-content: center;
  justify-content: center;
}

.warningText {
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
}
