.investmentAfterRecommendationsContainer {
  position: relative;
  height: 215px;
  width: 1143px;
  background: #f5f8fa;
  border-radius: 4px;
}

.investmentAfterRecommendationsField {
  display: flex;
  height: 36px;
}

.investmentAfterRecommendationsItem {
  position: absolute;
  left: 265px;
}
